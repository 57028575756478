@charset "utf-8";

@use '../../shared/scss/variable'  as *;
@use '../../shared/scss/mixin'  as *;
@use 'news_variable'  as *;
@use "sass:math";


/*-------------------------------------------
loading
-------------------------------------------*/
.bl_loading{
  position: fixed;
  top: 0;
  left:0;
  z-index: 101;
  width: 100%;
  height:100%;
  opacity:0;
  background: rgba(255,255,255,.7);
  animation: fadeOutAnime 0.3s linear both;
  &.-part{
    position:relative;
    width: 100%;
    max-height:60px;
    margin-top:-60px;
  }
  &.is_show{
    visibility: visible;
    animation: fadeInAnime 0.5s linear both;
  }
  .cv-spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinner {
    width: 60px;
    height: 60px;
    border: 5px #ddd solid;
    border-top: 5px solid #000;
    border-radius: 50%;
    animation: spinnerAnime 0.8s infinite linear;
  }
}

@keyframes spinnerAnime {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInAnime {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeOutAnime {
  0% {opacity:1;}
  99% {opacity:0;}
  100%{opacity:0; visibility: hidden;}
}